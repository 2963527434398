<template>
  <div>
    <Breadcrumb :style="{ margin: '20px 10px' }">
      <BreadcrumbItem>电子券管理</BreadcrumbItem>
      <BreadcrumbItem>发券</BreadcrumbItem>
    </Breadcrumb>
    <loading :loading="loading" :error="error"></loading>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <Form ref="sendData" :model="sendData" :rules="ruleValidate" label-position="right" :label-width="200" v-if="sendData">
      <FormItem label="公司名称" prop="company">
        <dropdown @item-click="dropDownClick" :isNeedSearch="true" :companies="companies"></dropdown>
      </FormItem>
      <FormItem label="客户ID" prop="gn_id">
        <Input v-model="sendData.gn_id" placeholder="请输入客户ID"></Input>
      </FormItem>
      <FormItem label="券分类ID" prop="category_id">
        <Select v-model="sendData.category_id">
          <Option v-for="category in categories" :value="category.category_id" :key="category.category_id">{{ category.title }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="handleSubmit('sendData')">发券</Button>
        <Button style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import Dropdown from '../member/components/dropdown.vue'
import loading from '../loading.vue';
import axios from "axios";
export default {
  components: { Dropdown, loading },
  data () {
    return {
      loading: false,
      error: null,
      companies: [],
      sendData: {},
      categories: null,
      ruleValidate: {
        gn_id: [
          {
            required: true,
            message: "客户ID不能为空",
            trigger: "blur",
          },
          {
            type: "string",
            message: "名字是字符串",
            trigger: "blur",
          },
        ],
        category_id: [
          {
            required: true,
            message: "券分类ID不能为空",
            trigger: "blur",
          }
        ],
      },
    }
  },
  created () {
    axios.get("company?type=0").then(res => {
      this.companies = res.data.companies
    })
    this.axios.get("couponCategories/totalList").then((res) => {
      console.log("res", res);
      this.categories = res.data.coupon_categories;
      this.loading = false;
    });
  },
  methods: {
    dropDownClick(e) {
      this.sendData.company_id = e.id;
    },
    handleSubmit(name) {
      if (!this.sendData.company_id){
        this.$Message.error("公司不能为空!");
        return;
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          // 验证通过
          console.log("验证通过");
          this.loading = true;
          this.axios
              .post("coupons/getCoupons", this.sendData)
              .then((res) => {
                if (res.data.status === 200) {
                  this.$Message.success('发券成功');
                  setTimeout(() => {
                    this.$router.push("/pages/coupons");
                  }, 2000);
                } else {
                  this.$Message.error(res.data.msg);
                }
              });
        } else {
          this.$Message.error("请输入正确的数据!");
        }
      });
    },
  },
}
</script>

<style scoped>

</style>