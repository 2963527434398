/*
 * @Author: 陈远锋
 * @Date: 2022-03-04 10:17:03
 * @LastEditTime: 2023-01-04 10:29:11
 * @FilePath: \src\config\app.js
 * @Description: Do not edit
 */
const isTest = 0;

module.exports = {

	// 正式环境
	HTTP_REQUEST_URL: isTest == 0 ? 'https://zgsy.zjgnwl.com/api' : 'https://zgsy-test.zjgnwl.com/api', // 正式环境

	// 以下配置在不做二开的前提下,不需要做任何的修改
	HEADER: {
		'content-type': 'application/json',
		//#ifdef H5
		'Form-type': navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1 ? 'wechat' : 'h5',
		//#endif
		//#ifdef MP
		//'Form-type': 'routine',
		//#endif
		//#ifdef APP-VUE
		//'Form-type': 'app',
		//#endif
	},
	// 回话密钥名称 请勿修改此配置
	TOKENNAME: 'Authorization',
	// 缓存时间 0 永久
	EXPIRE: 86400,

	//分页最多显示条数
	LIMIT: 10,
}
