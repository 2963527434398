<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-27 14:18:44
 * @FilePath: \qyOut\src\pages\user\list.vue
 * @Description: Do not edit
-->
<template>
  <div>
    <Breadcrumb :style="{ margin: '20px 10px' }">
      <BreadcrumbItem>电子券管理</BreadcrumbItem>
      <BreadcrumbItem>下架列表</BreadcrumbItem>
    </Breadcrumb>
    <loading :loading="loading" :error="error"></loading>
    <Form ref="selectForm" :model="selectForm" inline>
      <FormItem prop="company_id">
        <Select v-model="selectForm.company_id" style="width: 250px; margin-right: 10px; margin-left: 10px;">
          <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.company_name }}</Option>
        </Select>
      </FormItem>
      <FormItem prop="category_id">
        <Select v-model="selectForm.category_id" style="width: 250px; margin-right: 10px; margin-left: 10px;">
          <Option v-for="category in categories" :value="category.category_id" :key="category.category_id">{{ category.title }}</Option>
        </Select>
      </FormItem>
      <FormItem prop="alias">
        <Input v-model="selectForm.alias" placeholder="别名"></Input>
      </FormItem>
      <FormItem prop="cou_category">
        <RadioGroup @on-change="couCategoryChanged">
          <Radio label="10">
            <span>满减券</span>
          </Radio>
          <Radio label="40">
            <span>折扣券</span>
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
        <!--          <Button type="primary" @click="goExportII()" style="margin-left: 5px;">导出</Button>-->
      </FormItem>
    </Form>
    <Table border stripe v-if="coupon_categories" :columns="columns1" :data="coupon_categories">
      <template slot-scope="{ row }" slot="company_name">
        {{ row.company.company_name }}
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">详情</Button>
        <Button type="primary" size="small" style="margin-right: 5px" @click="offShelf(row)">上架</Button>
      </template>
    </Table>
    <expandRow style="display: none"></expandRow>
    <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
  </div>
</template>
<style>
.ivu-table .table-info-warm-row td{
  color: #f90101 !important;
}
</style>
<script>
import loading from "../loading.vue";
export default {
  components: { loading },
  data () {
    return {
      loading: false,
      users: null,
      error: null,
      categories: null,
      pages: {
        total: 0
      },
      companies: [],
      selectForm: {},
      coupon_categories: null,
      columns1: [
        /* { title: "时间", key: "updated_at", align: "center" }, */
        { title: "ID", key: "category_id", align: "center", minWidth: 80 },
        { title: "名称", key: "title", align: "center", minWidth: 100 },
        { title: "别名", key: "alias", align: "center", minWidth: 100 },
        { title: "公司名称", slot: "company_name", align: "center", minWidth: 100 },
        { title: "类型", key: "category_desc", align: "center", minWidth: 100 },
        { title: "面额", key: "face_value", align: "center", minWidth: 80 },
        { title: "类型编码", key: "biz_type_desc", align: "center", minWidth: 100 },
        { title: "库存", key: "cnt", align: "center", minWidth: 80 },
        { title: "到期提醒时间", key: "warn_time", align: "center", minWidth: 100 },
        {
          title: "操作",
          slot: "action",
          width: 250,
          align: "center",
        },
      ],
    };
  },
  created () {
    console.log("mounted");
    this.fetchData();
    this.axios.get('company').then(res => {
      this.companies = res.data.companies
    })
    this.axios.get("couponCategories/totalList").then((res) => {
      console.log("res", res);
      this.categories = res.data.coupon_categories;
      this.loading = false;
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchData",
  },
  methods: {
    fetchData (url = 'couponCategories/offShelfList', params = {}) {
      this.error = null;
      this.loading = true;
      let data = {};
      for (let key in params) {
        console.log('param[key],', params[key]);
        if (params[key] === undefined || params[key].length === 0) {
          delete params[key];
        }
      }
      data.params = params;
      this.axios.get(url, data).then((res) => {
        console.log("res", res);
        this.coupon_categories = res.data.coupon_categories;
        // this.coupon_categories.map(item => {
        // return item.cellClassName = {alias:'demo-table-info-cell-Asia'}
        // })
        console.log(this.coupon_categories);
        this.pages = res.data.meta;
        this.loading = false;
      });
    },
    pageOnChange: function (page) {
      this.pages.links.forEach(link => {
        if (link.label == page) {
          this.fetchData(link.url);
          return;
        }
      })
    },
    handleSubmit: function (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.fetchData('couponCategories/offShelfList', this.selectForm);
        } else {
          this.$Message.error('出错了');
        }
      })
    },
    couCategoryChanged (val) {
      console.log('couCategoryChanged val=>', val);
      this.selectForm.cou_category = val;
    },
    show (row, index) {
      console.log("row=>", row.id);
      console.log("index=>", index);
      this.$router.push("/pages/coupon_categories_detail/" + row.id);
    },
    offShelf (row) {
      this.axios.get("couponCategories/onShelf/" + row.id).then((res) => {
        console.log("res",res);
        if (res.data.status === 200) {
          this.$Message.success("上架成功");
          let index = -1;//这里不能设置0，因为数组索引是从0开始的
          for (let i = 0; i < this.coupon_categories.length; i++) {
            if (this.coupon_categories[i].id == row.id) {
              index = i;
              break
            }
          }
          if (index >= 0) {
            this.coupon_categories.splice(index, 1);
          }
        } else {
          this.$Message.error(res.data.msg);
        }
        this.loading = false;
      });
    },
  },
};
</script>
